/* Tables */
table {
  border-spacing: 0;
  border-color: #eee;
  font-size: inherit;
  font: 100%;
  margin: 10px 0;
  padding: 0;
  width: 100%;
}
table.sticky-header {
  z-index: 10;
}
table,
td,
th {
  vertical-align:middle;
}
caption,
th,
td {
  text-align:left;
}
thead th {
  border-bottom: 1px solid #eee;
  color: #494949;
  font-weight: 700;
}
td,
th {
  border-bottom: none;
  margin: 0;
  padding: 5px 7px;
}
tr.even,
tr.odd {
  border-bottom: none;
}
tr.odd,
tr.info {
  background-color: #f5f5f5;
}
tr.even {
  background-color: #fff;
}
tr.drag {
  background-color: #fffff0;
}
tr.drag-previous {
  background-color: #ffd;
}
tr.odd td.active {
  background-color: #eee;
}
tr.even td.active {
  background-color: #f7f7f7;
}
td.region,
td.module,
td.container td.category {
  background-color: #eee;
  border-bottom: 1px solid #ccc;
  border-top: 20px solid #fff;
  color: #222;
  font-weight: 700;
}
tr:first-child td.region,
tr:first-child td.module,
tr:first-child td.container {
  border-top-width: 0;
}

/**
 * Webkit work-around. Uncomment and adjust if you have borders on td.
 */
/*
tr td:last-child {
  border-right: 1px solid #eee;
}
*/
